import React, { useEffect, useMemo, useReducer, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { useRouter } from "next/router";
import { appWithTranslation } from "next-i18next";
import type { AppProps } from "next/app";
import { createTheme, NextUIProvider } from "@nextui-org/react";
import { ToastContainer } from "react-toastify";
import { GoogleAnalytics } from "nextjs-google-analytics";
import Cookies from "js-cookie";

import CookieAlert from "@components/layout/Cookie";
import Wrapper from "@components/layout/Wrapper";
import { GlobalContext, initialState } from "@context/GlobalContext";
import { GlobalContextReducer } from "@reducer/GlobalContextReducer";
import { ErrorBoundary } from "app/ErrorBoundary";
import { CryptoPair, CryptoType } from "@common/type/type";
import { COOKIE_LANGUAGE } from "@common/constant/constant";
import { AppLanguage } from "@common/enum/enum";

import "@styles/globals.scss";
import "react-toastify/dist/ReactToastify.css";
import Script from "next/script";

const theme = createTheme({
  type: "dark",
  theme: {
    colors: {
      primary: "#00000000",
    },
  },
});

export type CustomAppProps = {
  crypto: CryptoType[];
  initialPair: CryptoPair;
};

function MyApp({ Component, pageProps }: AppProps & CustomAppProps) {
  const [queryClient] = useState(() => new QueryClient());
  const [state, dispatch] = useReducer(GlobalContextReducer, {
    ...initialState,
  });
  const router = useRouter();

  useEffect(() => {
    let lang = Cookies.get(COOKIE_LANGUAGE);
    const isAppLanguage = Object.values(AppLanguage).find(
      appLang => appLang === lang
    );

    if (lang === "uk") {
      lang = AppLanguage.UA;
    }
    if (!isAppLanguage) {
      lang = AppLanguage.EN;
    }

    const { pathname, asPath, query } = router;

    router.push({ pathname, query }, asPath, { locale: lang });
  }, []);

  const WrappedErrorBoundary = useMemo(() => ErrorBoundary, []);
  const ContextProviderValue = useMemo(
    () => ({ state, dispatch }),
    [state, dispatch]
  );

  return (
    <GlobalContext.Provider value={ContextProviderValue}>
      <QueryClientProvider client={queryClient}>
        <WrappedErrorBoundary>
          <NextUIProvider theme={theme}>
            <Wrapper>
              <Script
                strategy="lazyOnload"
                src="https://www.googletagmanager.com/gtag/js?id=G-P26RQXJ7EL"
              />

              <Script strategy="lazyOnload" id="test">
                {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', 'G-P26RQXJ7EL')`}
              </Script>
              <Component {...pageProps} />
            </Wrapper>
          </NextUIProvider>
          <ToastContainer autoClose={2000} />
          <CookieAlert />
        </WrappedErrorBoundary>
      </QueryClientProvider>
    </GlobalContext.Provider>
  );
}

export default appWithTranslation(MyApp);
